import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ alt, classNames, src }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1920, quality: 99) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find(n => n.node.relativePath === src);

  if (!image) return null;

  return (
    <Img
      alt={alt}
      backgroundColor="rgb(245, 245, 245)"
      className={classNames}
      fluid={image.node.childImageSharp.fluid}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  classNames: PropTypes.string,
  src: PropTypes.string.isRequired
};

Image.defaultProps = {
  alt: 'Default Attribute',
  classNames: null
};

export default Image;
